import { type Field } from '@core/types';
import { formattedFieldValue, isEmpty } from '@core/utils';
import { createAction, createReducer } from '@reduxjs/toolkit';

interface IAdditionalFieldsSettings {
  additionalFieldValues: null | Record<string, string>;
  collapsibleFields: Field[];
  isAdditionalFieldValuesInvalid: boolean;
  notCollapsibleFields: Field[];
}

const initialState: IAdditionalFieldsSettings = {
  collapsibleFields: [],
  notCollapsibleFields: [],
  additionalFieldValues: null,
  isAdditionalFieldValuesInvalid: false,
};

export const setAdditionalFieldsData = createAction<Field[]>(
  'appAdditionalFieldsSettings/setAdditionalFieldsData'
);

export const setAdditionalFieldValues = createAction<Field[] | null>(
  'appAdditionalFieldsSettings/setAdditionalFieldValues'
);

export const setIsAdditionalFieldValuesInvalid = createAction<boolean>(
  'appAdditionalFieldsSettings/setIsAdditionalFieldValuesInvalid'
);

export const appAdditionalFieldsSettings = createReducer(
  initialState,
  (builder) => {
    builder.addCase(setAdditionalFieldsData, (state, action) => {
      if (action.payload.length > 0) {
        const notCollapsible = [];
        const collapsible = [];

        for (const field of action.payload) {
          if (!isEmpty(field)) {
            if (field.alwaysVisible) {
              notCollapsible.push({
                ...field,
                value: state.additionalFieldValues?.[field.name] ?? field.value,
              });
            } else {
              collapsible.push({
                ...field,
                value: state.additionalFieldValues?.[field.name] ?? field.value,
              });
            }
          }
        }

        state.collapsibleFields = collapsible;
        state.notCollapsibleFields = notCollapsible;
      } else {
        state.collapsibleFields = [];
        state.notCollapsibleFields = [];
      }
    });
    builder.addCase(setAdditionalFieldValues, (state, action) => {
      if (action.payload === null) {
        state.additionalFieldValues = null;
      } else {
        const values = action.payload?.reduce<Record<string, string>>(
          (acc, { name, value }) => {
            acc[name] = formattedFieldValue(name, value);

            return acc;
          },
          {}
        );
        state.additionalFieldValues = values;
      }
    });
    builder.addCase(setIsAdditionalFieldValuesInvalid, (state, action) => {
      state.isAdditionalFieldValuesInvalid = action.payload;
    });
  }
);
